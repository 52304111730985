import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './Inscription.Template.vue';
import InscriptionValidator from './Inscription.Validator';
import { Utilisateur, Civilite, FormeJuridique, PrescripteurInterne } from '@/models';
import { Profils, TypeCompteSyndic as TypeCompteSyndicEnum } from '@/shared/enums';
import { UtilisateurApiHelper } from '@/services/UtilisateurApiHelper';
import { ApiAutocompleteHelper } from '@/services/ApiAutocompleteHelper';
import CeeAdresse from '@/components/CeeAdresse.vue';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { Getter, Action } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { TypeCompteSyndic } from '@/models/TypeCompteSyndic.model';
import ApiHelper from '@/services/ApiHelper';
import { ApiService } from '@/services/base/ApiService';
import { FileContentResult } from '@/shared/models/index';
import { Contact } from '../../../models/Contact.model';
import { Societe } from '../../../models/Societe.model';
import { TypeContact } from '@/shared/enums/TypeContact.enum';
import { FormHelper } from '../../../shared/helpers/FormHelper';

@Component({
    ...template,
    name: 'Inscription',
    components: { CeeAdresse, CeeAutocomplete }
})
export default class Inscription extends Mixins(InscriptionValidator) {

    public $refs!: {
        form: HTMLFormElement,
        emailForm: HTMLFormElement
    };

    /** Profil du compte à créer, passé via l'url. */
    @Prop() public readonly codeProfil!: string;

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TypeCompteSyndic))
    public typesCompteSyndic: TypeCompteSyndic[];
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Civilite))
    public civilites: Civilite[];
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.FormeJuridique))
    public formesJuridiques: FormeJuridique[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    private utilisateur: Utilisateur = new Utilisateur();
    public aPrescripteur: boolean = false;
    public emailConfirm: string = null;
    public passwordConfirm: string = null;
    public acceptationCGU: boolean = false;
    public confirmationInformations: boolean = false;
    public loading: boolean = false;

    /**
     * Permet à l'utilisateur de basculer le mode d'affichage du mot de passe afin de vérifier le texte qu'il a entré.
     */
    public afficherPassword: boolean = false;
    public afficherPasswordConfirm: boolean = false;

    public get emailConfirmationRule(): (() => boolean | string) {
        return () => (this.utilisateur.email === undefined || this.utilisateur.email === this.emailConfirm) || 'L\'adresse mail de confirmation est incorrecte.';
    }

    public get passwordConfirmationRule(): (() => boolean | string) {
        return () => (this.utilisateur.password === this.passwordConfirm) || 'Le mot de passe et le mot de passe de confirmation ne correspondent pas.';
    }

    /**
     * Liste des types de profil acceptés.
     */
    private readonly typesProfilsAcceptes: { [code: string]: { typeProfil: Profils, libelle: string } } = {
        'entreprise': { typeProfil: Profils.Entreprise, libelle: 'Entreprise' },
        'installateur': { typeProfil: Profils.Installateur, libelle: 'Installateur' },
        'syndic-sdc': { typeProfil: Profils.SyndicSDC, libelle: 'Syndic/SDC' }
    };

    /**
     * Récupère le type de profil de l'url en vérifiant qu'il soit parmis ceux autorisés.
     */
    public get typeProfil(): Profils {

        // Essaye de trouver le profil parmi ceux autorisés.
        const profil = this.typesProfilsAcceptes[this.codeProfil];
        if (profil) {
            return profil.typeProfil;
        }

        // Si on n'est pas sur un type de profil autorisé, redirige vers une page d'erreur 404.
        this.$router.push('/not-found');
        return Profils.None;
    }

    /**
     * Récupère le libellé du profil de l'url.
     */
    public get libelleProfil(): string {
        const profil = this.typesProfilsAcceptes[this.codeProfil];
        if (profil) {
            return profil.libelle;
        }
        return '';
    }

    // Règle de validation propre au SIRET.
    public siretRequisRule = [
        (v: string | any) => this.checkPresenceSiret() || 'Le numéro SIRET est obligatoire. Confirmez que vous n\'en possédez pas, ou saisissez le.',
    ];

    /**
     * Vérifie que le est SIRET est présent sauf dérogation.
     */
    private checkPresenceSiret(): boolean {
        // Dans le cadre d'une inscription, il y a toujours 1 seule société.
        const societe = this.utilisateur.societes[0];
        const siretSaisi = (societe.siret.length > 0);

        if (this.estTypeCompteSDC) {
            // On autorise un SIRET vide seulement si on est un Syndic qui a déclaré ne pas avoir de SIRET avec la checkbox prévue à cet effet.
            return (societe.estSansSiret || siretSaisi);
        }

        return siretSaisi;
    }

    // Vrai si le SIRET est obligatoire, faux sinon.
    public get estSiretRequis(): boolean {
        return !(this.estTypeCompteSDC && this.utilisateur.societes[0].estSansSiret);
    }

    // Vrai si le type de profil est Syndic SDC, faux sinon.
    public get estTypeProfilSyndicSDC(): boolean {
        return (this.typeProfil === Profils.SyndicSDC);
    }

    // Vrai si le type de compte est SDC, faux sinon.
    public get estTypeCompteSDC(): boolean {
        return this.estTypeProfilSyndicSDC && this.utilisateur.typeCompteSyndicId === TypeCompteSyndicEnum.SDC;
    }

    public mounted(): void {
        this.getValeursReferentielles(TypeValeurReferentielle.Civilite);
        this.getValeursReferentielles(TypeValeurReferentielle.FormeJuridique);
        this.getValeursReferentielles(TypeValeurReferentielle.TypeCompteSyndic);
    }

    /**
     * Promesse pour l'autocomplete des prescripteurs internes.
     * @param nom Critère de recherche.
     */
    public prescripteursInternesPromise(recherche: string): Promise<PrescripteurInterne[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<PrescripteurInterne>(recherche, `/prescripteurInterne/obtenirTous`);
    }

    public submit() {
        if (this.$refs.form.validate() && this.$refs.emailForm.validate()) {
            this.loading = true;
            this.utilisateur.profilUtilisateur = this.typeProfil;
            this.utilisateur.userName = this.utilisateur.email;

            this.utilisateur.societes.forEach(
                (s) => {
                    if (s.estUtilisateurInterlocuteur) {
                        // Supprime le contact existant si l'utilisateur courant est maintenant l'interlocuteur.
                        s.contacts = s.contacts.filter(c => c.typeContactId !== TypeContact.Interlocuteur);
                        // L'interlocuteur devient l'utilisateur courant.
                        s.interlocuteur = new Contact(TypeContact.Interlocuteur, this.utilisateur);
                    }
                    if (s.estUtilisateurSignataire) {
                        // Supprime le contact existant si l'utilisateur courant est maintenant le signataire.
                        s.contacts = s.contacts.filter(c => c.typeContactId !== TypeContact.Signataire);
                        // Le signataire devient l'utilisateur courant.
                        s.signataire = new Contact(TypeContact.Signataire, this.utilisateur);
                    }

                    s.contacts.push(s.interlocuteur);
                    s.contacts.push(s.signataire);
                });

            UtilisateurApiHelper.creerUtilisateur({
                utilisateur: this.utilisateur,
            }).then((result: any) => {
                const data = result.data;
                if (data && !data.isError) {
                    (this.$router as any).push({ name: 'login' });
                }
            }).finally(() => this.loading = false);
        } else if (!FormHelper.focusOnFirstInvalidInput(this.$refs.form)) {
            FormHelper.focusOnFirstInvalidInput(this.$refs.emailForm);
        }
    }

    public clear(): void {
        this.$refs.form.reset();
        this.$refs.emailForm.reset();
    }

    /**
     * Télécharge les CGUs
     *
     */
    public telechargerCgus(): void {
        const telechargerCguService = new ApiService<FileContentResult>(`/utilisateur/telechargerCgus`);
        telechargerCguService.getWhereSingle('').then((reponse) => {
            ApiHelper.createAndDownloadBlobFile(reponse.data);
        });
    }

    /**
     * Reset du capital et du lieu d'immatriculation
     * lorsque l'utilisateur coche la case 'Je n'ai pas de siret'.
     * @param societe
     */
    private onChangeSansSiret(societe: Societe): void {
        if (societe && societe.estSansSiret) {
            societe.capital = null;
            societe.lieuImmatriculation = null;
        }
    }

    /**
     * Reset le champ email confirmation en cas de modification de l'email.
     */
    private onChangeEmail(): void {
        this.$refs.emailForm.validate();
    }
}
