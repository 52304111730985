import { CompteValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component({})
export default class InscriptionValidator extends CompteValidationMixin {

    // Prescripteur Interne.
    private prescripteurInterneRules = [
        (v: string | any) => this.required(v) || 'Le prescripteur interne est obligatoire',
    ];

    private acceptationCGURules = [
        (v: string | any) => this.required(v) || 'Veuillez prendre connaissance et accepter les conditions générales d\'utilisation',
    ];

    private confirmationInformationsRules = [
        (v: string | any) => this.required(v) || 'La confirmation des informations saisies est obligatoire',
    ];

    private verifierSiretRules = [
        (v: string | any) => this.required(v) || 'Le siret est obligatoire',
        (v: string | any) => this.verifySiret(v) || 'Le siret n\'est pas valide',
    ];
}
