import { HttpClient } from '../shared/HttpClient';
import { UtilisateurIdentity, UtilisateurEmail } from '@/components/FormsUtilisateur/models';
import { Societe, Utilisateur } from '@/models';
import { UtilisateurMotDePasse } from '@/views/Compte/Profil/models';
import { UtilisateurActivationCriteria } from '@/services/models/UtilisateurActivationCriteria';

/**
 * Classe d'helper pour les appels au back pour un utilisateur.
* */
// tslint:disable-next-line:no-namespace
export module UtilisateurApiHelper {

    // Update de l'identité d'un utilisateur.
    export function dispatchModifierUtilisateurIdentite(payload: { utilisateurIdentite: UtilisateurIdentity }): Promise<boolean> {
        return modifierUtilisateurChild('modifierUtilisateurIdentite', payload.utilisateurIdentite);
    }
    // Update de l'identité d'un utilisateur.
    export function dispatchDefinirDateDesactivation(payload: { model: {id: number, datePlanifieDesactivation: string} }): Promise<boolean> {
        return modifierUtilisateurChild('definirDateDesactivationPlanifiee', payload.model);
    }

    // Modification des sociétés d'un utilisateur.
    export function dispatchModifierUtilisateurEntreprise(payload: { utilisateurSocietes: Societe[] }): Promise<boolean> {
        const societes = payload.utilisateurSocietes;
        return modifierUtilisateurChild('modifierUtilisateurSocietes', societes);
    }

    // Modification du mot de passe d'un utilisateur.
    export function dispatchModifierUtilisateurMotDepasse(payload: { mdpUtilisateur: UtilisateurMotDePasse }): Promise<boolean> {
        return modifierUtilisateurChild('modifierUtilisateurMotDePasse', payload.mdpUtilisateur);
    }

    // Modification du mail d'un utilisateur.
    export function dispatchModifierUtilisateurEmail(payload: { utilisateurEmail: UtilisateurEmail }): Promise<boolean> {
        return modifierUtilisateurChild('modifierUtilisateurEmail', payload.utilisateurEmail);
    }

    // Update de l'identité d'un utilisateur Admin.
    export function dispatchModifierUtilisateurAdminIdentite(payload: { utilisateurIdentite: UtilisateurIdentity }): Promise<boolean> {
        return modifierUtilisateurChild('modifierUtilisateurAdminIdentite', payload.utilisateurIdentite);
    }

    // Modification des sociétés d'un utilisateur Admin.
    export function dispatchModifierUtilisateurAdminEntreprise(payload: { utilisateurSocietes: Societe[] }): Promise<boolean> {
        const societes = payload.utilisateurSocietes;
        return modifierUtilisateurChild('modifierUtilisateurAdminSocietes', societes);
    }

    // Modification du mail d'un utilisateur Admin.
    export function dispatchModifierUtilisateurAdminEmail(payload: { utilisateurEmail: UtilisateurEmail }): Promise<boolean> {
        return modifierUtilisateurChild('modifierUtilisateurAdminEmail', payload.utilisateurEmail);
    }

    // Création d'un utilisateur.
    export function creerUtilisateur(payload: { utilisateur: Utilisateur }): Promise<Utilisateur> {
        return postUtilisateur('creer', payload.utilisateur);
    }

    // Création d'un utilisateur Admin.
    export function creerAdminUtilisateur(payload: { utilisateur: Utilisateur }): Promise<Utilisateur> {
        return creerUtilisateur(payload);
    }

    // Confirmation de l'email d'un utilisateur.
    export function confirmEmail(payload: { userId: string, code: string }): Promise<boolean> {
        return new Promise((resolve) => {
            const criteria: UtilisateurActivationCriteria = new UtilisateurActivationCriteria(payload.userId, payload.code);
            HttpClient.axios.get(`/utilisateur/confirmEmail?${criteria.queryString}`)
                .then(() => resolve(true))
                .catch((_: any) => resolve(false));
        });
    }

    // Méthode en cas d'oubli de mot de passe.
    export function forgotPassword(payload: { email: string }): Promise<Utilisateur> {
        return postUtilisateur('forgotPassword', payload);
    }

    // Reset du mot de passe.
    export function resetPassword(payload: { email: string, password: string, code: string }): Promise<Utilisateur> {
        return postUtilisateur('resetPassword', payload);
    }

    // Appel à l'api pour effectuer une modification sur un utilisateur.
    function modifierUtilisateurChild<T>(childRoute: string, data: T): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            HttpClient.axios.put(`/utilisateur/${childRoute}/`, data)
                .then(() => resolve(true))
                .catch((_: any) => resolve(false))
        });
    }

    // Appel à l'api pour ajouter un utilisateur.
    function postUtilisateur<T>(childRoute: string, data: T): Promise<Utilisateur> {
        return new Promise<Utilisateur>((resolve, reject) => {
            HttpClient.axios.post(`/utilisateur/${childRoute}/`, data)
                .then((reponse: any) => resolve(reponse))
                .catch((_: any) => reject(_))
        });
    }
}

export default UtilisateurApiHelper;