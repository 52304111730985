export class FormHelper {

    /**
     * Focus sur la première input en erreur.
     * @param form Formulaire.
     */
    public static focusOnFirstInvalidInput(form: HTMLFormElement): boolean {
        let input: HTMLElement = null;
        let i = 0;

        if (!form) {
            throw new Error('Erreur : le formulaire ne peut pas être null');
        }

        while (!input || ((input as any).valid && i < form.inputs.length)) {
            input = form.inputs[i];
            ++i;
        }

        if (!input || (input as any).valid) {
            return false;
        }

        if (input.hasOwnProperty('focus')) {
            input.focus();
        }

        return true;
    }
}
