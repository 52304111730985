import { CriteriaBase } from '@/shared/models';

/**
 * Classe de modèle pour l'activation de compte.
 *
 */
export class UtilisateurActivationCriteria extends CriteriaBase {

    /**
     * UserId du compte utilisateur à activer.
     */
    public userId: string = '';

    /**
     * Token d'authentification pour autoriser l'appel API.
     */
    public token: string = '';

    /**
     * Constructeur.
     * @param userId UserId du compte utilisateur à activer.
     * @param token Token d'authentification pour autoriser l'appel API.
     */
    constructor(userId: string, token: string) {
        super();

        this.userId = userId;
        this.token = token;
    }
}
